<template>
  <create-website initialTitle="wi-title-create-members-area"
                  initialSubtitle="wi-desc-create-members-area"
                  initialImg="members-area.svg"
                  :finishOnWebsite="false"
                  :selectedCategory="8"
                  :stepChange="triggerChildMethod"
                  ref="createWebsite">

    <div class="container" v-if="stepMembers === 3">
      <title-bar title="wi-title-create-members-type"
                 class=" mb-5"
                 sub-title="wi-desc-create-members-type">
        <button class="btn btn-secondary btn-back" @click="backStep(3)">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <stepName :handleChange="handleChange" :handleChangeColor="handleChangeColor" title="wi-title-choose-name-members-area" :withoutSpacing="true"/>

      <stepName class="mt-5" :handleChange="handleChangeDesc"  title="wi-title-choose-desc-members-area" :withoutSpacing="true"/>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStepMembers" v-if="membersName !== ''">
            {{ $t('ct-next-step') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

    </div>


    <div class="container" v-if="stepMembers === 4">

      <title-bar title="wi-title-create-members-template-confirm"
                 sub-title="wi-desc-create-members-template-confirm">
        <button class="btn btn-secondary btn-back" @click="backStepMembers(4)">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-4 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ getDataFromWebsite('websiteName') }}</h4>
            <small>{{ $t('wi-name-of-site') }}</small>
            <br/>
            <img src="@/assets/steps/nicho.svg">
            <h4 class="mt-3">{{ $refs.createWebsite.getCategoryName() }}</h4>
            <small>{{ $t('wi-category-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img class="card-img-top"
                 :src="'https://sitebuilder-admin.omb11.com/storage/'+getDataFromWebsite('templateSelected').thumbnail">
            <h4 class="mt-3">{{ getDataFromWebsite('templateSelected').text }}</h4>
            <small>{{ $t('wi-template-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ membersName }}</h4>
            <small>{{ $t('wi-name-of-members') }}</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-success btn-create-website" @click="createMembers">
            {{ $t('wi-create-members') }}
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="container" v-if="stepMembers === 5">

      <title-bar title="wi-title-create-website-goto"
                 sub-title="wi-desc-create-website-goto">
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-6 align-self-center">
            <a :href="url" target="_blank">
              <button class="btn btn-success btn-create-website">
                {{ $t('wi-edit-website') }}
              </button>
            </a>
          </div>

          <div class="col-6 align-self-center">
            <a :href="publishUrl" target="_blank">
              <button class="btn btn-success btn-create-website">
                {{ $t('wi-goto-website') }}
              </button>
            </a>
          </div>
        </div>
      </div>

    </div>

  </create-website>
</template>

<style>
.title-blue {
  color: #0F7BFF;
  font: normal normal 500 20px/25px Mulish;
  letter-spacing: 0;
  opacity: 1;
}

br {
  display: block;
  margin: 10px 0;
  content: " ";
}

.desc-members {
  text-align: center;
  font: normal normal normal 14px/16px Mulish;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}

</style>

<script>

import createWebsite from '../CreateWebsite/index'
import titleBar from "@/components/titleBar";
import stepName from "@/components/stepName";
import doMagic from "@/components/doMagic";

export default {
  name: 'MembersArea',
  data() {
    return {
      stepMembers: 0,
      membersName: '',
      membersColor: '#79c120',
      membersDesc: '',

      url: null,
      publishUrl: null
    }
  },
  components: {
    createWebsite,
    titleBar,
    stepName
  },
  methods: {
    changeUrlButton(url, publishUrl) {

      this.nextStepMembers();

      console.log(url, publishUrl);

      this.url = url;
      this.publishUrl = publishUrl;
    },
    getDataFromWebsite(param) {

      console.log(this.$refs.createWebsite, param);

      return this.$refs.createWebsite[param];
    },
    createMembers() {

      console.log(this.$refs.createWebsite);

      let obj = {
        members:true,
        membersName: this.membersName,
        membersColor: this.membersColor,
        membersDesc: this.membersDesc,

        smart_tags: Object.assign({}, this.$refs.createWebsite.smart_tags_vales),
        websiteName: this.$refs.createWebsite.websiteName,
        template_id: this.$refs.createWebsite.templateSelected.website.encrypted_id
      }
      console.log(obj);

      doMagic(obj, this);

    },
    handleChange(value) {
      this.membersName = value;
    },
    handleChangeDesc(value) {
      this.membersDesc = value;
    },
    handleChangeColor(value) {

      console.log(value);
      this.membersColor = value;
    },
    backStepMembers(step) {
      this.stepMembers = step;
    },
    backStep(step) {
      this.$refs.createWebsite.step = step;
      this.stepMembers = step;
    },
    triggerChildMethod(step) {
      this.stepMembers = step;
    },
    nextStepMembers() {
      this.stepMembers = this.stepMembers + 1;
    },
    nextStepW() {
      this.stepMembers = this.stepMembers + 1;
    }
  },
}
</script>
