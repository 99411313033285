<template>
  <div :class="'row '+(!withoutSpacing? 'py-5 my-5':'')">
    <div class="col-8 mx-auto">
      <div class="w-100" style="text-align: left">
        <span>{{ $t(title) }}</span>
      </div>
      <div class="card card-input">
        <input class="form-control" :placeholder="$t(title)" @keyup="()=>this.handleChange(this.name)"
               @change="()=>this.handleChange(this.name)"
               v-model="name"/>
        <div class="input-picker" v-if="handleChangeColor!==null">
          <input type="color" id="head" name="head" v-model="color" @change="updateColor" @blur="updateColor"/>
        </div>

        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.input-picker {
  position: absolute;
  top: 43px;
  right: 43px;

  .color-button {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    float: right;
  }
}


</style>
<script>

export default {
  name: 'stepName',
  props: {
    title: String,
    handleChange: Function,
    handleChangeColor: {
      type: Function,
      default: null
    },
    withoutSpacing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    updateColor(event) {
      this.color = event.target.value;
      this.handleChangeColor(event.target.value)
    }
  },
  data() {
    return {
      name: '',
      color: '#79c120',
      showModal: false
    }
  }
}
</script>
