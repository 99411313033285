<template>
  <title-bar :title="title"
             :sub-title="subTitle">
<!--    <router-link :to="'/start'">-->
<!--      <button class="btn btn-secondary btn-back">-->
<!--        <i class="fas fa-arrow-left"></i>-->
<!--        {{ $t('ct-wizard-back') }}-->
<!--      </button>-->
<!--    </router-link>-->
  </title-bar>

  <div class="row">
    <div class="pt-5">
      <img :src="loadImg()" :alt="title" :title="title"/>
    </div>
  </div>
</template>


<script>
import TitleBar from "./titleBar";

export default {
  name: 'baseSteps',
  components: {TitleBar},
  props: {
    title: String,
    subTitle: String,
    img: String,
  },
  methods: {
    loadImg() {
      let images = require.context('../assets/steps/', false, /\.svg$/)
      return images('./' + this.img)
    }
  },
  created() {
    if(window.location.search.split('lang=')){
      this.$i18n.locale = window.location.search.split('lang=')[1];
    }
  }
};
</script>


