<template>
  <div class="container-fluid lateral-container">
    <div>
      <div class="row list-container">
        <div class="list-container-col my-2 col-12" v-for="(data) in results" :key="data.id">
          <div class="list-item-container" @click="redirects(data)">
            <div class="d-inline-flex w-100">
              <img class="list-item-container-img" :src="getImageData(data)" alt="data image"/>
              <div class="list-item-container-text-title mx-2">{{ data.title }}</div>
            </div>
            <div class="list-item-container-text-desc">
              {{ data.description.substring(0, 100) + (data.description.length > 100 ? '...' : '') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.lateral-container{
  background-color: #FFFFFF;
}

.lateral-container .list-item-container {
  padding: 10px;
}

.lateral-container .list-item-container-img {
  width: 40px;
  height: 40px;
  min-height: unset;
  margin-bottom: unset;
}

.lateral-container .list-item-container-text-title {
  text-align: left;
  font: normal normal bold 14px/18px Mulish;
  letter-spacing: -0.26px;
  color: #333333;
  opacity: 1;
  min-height: unset;
  max-height: unset;
  margin-bottom: 5px;
}

.lateral-container .list-item-container-text-desc {
  text-align: left;
  font: normal normal normal 14px/16px Mulish;
  letter-spacing: -0.22px;
  color: #333333;
  opacity: 1;
  min-height: unset;
  max-height: unset;
}

</style>


<script>

import '/src/assets/base.css'

import axios from "axios";

export default {
  name: 'Lateral',
  data() {
    return {
      keywords: null,
      results: [],
      amount: 10,
      resultsSuggestion: [],
    }
  },
  methods: {

    getImageData(item) {
      if (typeof item.img !== 'undefined' && item.img !== null) {
        return item.img;
      } else {
        if (typeof item.group !== 'undefined' && item.group.img !== null)
          return item.group.img;
      }
    },
    loadMore() {
      this.$Progress.start();
      setTimeout(() => {
        this.amount += 10;
        this.$Progress.finish();
      }, 400);
    },
    async redirects(item) {

      this.$Progress.start();

      await axios.post(`https://intelligence.builderall.com/amount/` + item.id);
      setTimeout(() => {
        this.$Progress.finish();
        window.parent.location.href = item.href;
      }, 300)
    }
  },
  async created() {
    try {

      this.email = this.$route.params.email;
      this.lang = this.$route.params.lang;

      this.$i18n.locale = this.lang;

      const response = await axios.get(`https://intelligence.builderall.com/contents/` + this.lang + `/` + this.email);
      this.results = response.data
    } catch (e) {
      console.log(e);
      this.errors.push(e)
    }
  }
};
</script>
<!--https://intelligence.builderall.com/contents/pt/h.cesar.silva@gmail.com-->
