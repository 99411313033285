import axios from "axios";

export default function (obj, vmThis) {

  let objPost = JSON.parse(JSON.stringify(obj));

  vmThis.$Progress.start();

  // if (process.env.VUE_APP_LOCAL) {
  //
  //   objPost['email'] = 'fernando.mazetti@gmail.com';
  //
  //   axios.post(process.env.VUE_APP_URL_API + '/wizard/do-magic', objPost).then(result => {
  //
  //
  //     vmThis.$Progress.finish();
  //     vmThis.changeUrlButton(result.data.url, result.data.access_url);
  //   });
  // } else {
    let xHttp = new XMLHttpRequest();
    xHttp.open(
      "GET",
      "https://office.builderall.com/us/office/login-status",
      true
    );
    xHttp.setRequestHeader("X-Requested-With", "XMLHttpRequest");
    xHttp.onreadystatechange = function () {
      if (xHttp.readyState == 4 && xHttp.status == 200) {
        let loggedOffice = JSON.parse(xHttp.responseText);

        if (loggedOffice.isLogged) {
          objPost['email'] = loggedOffice.email;

          axios.post(process.env.VUE_APP_URL_API + '/wizard/do-magic', objPost).then(result => {

            vmThis.$Progress.finish();
            vmThis.changeUrlButton(result.data.url, result.data.access_url);
          });
        }
      }
    };
    xHttp.withCredentials = true;
    // Do request
    xHttp.send();
  // }
}
