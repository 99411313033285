<template>
  <div class="search-container container-fluid">
    <div class="container">
      <title-bar title="wi-title-help-center"
                 sub-title="wi-desc-help-center"/>
      <search-bar :callback="sendSearch"/>
      <list-container ref="listResults" :amount-loaded="this.amount" v-if="results.length > 0 || error"
                      title="wi-results-help-center"
                      :classItem="'col-md-6 col-lg-3 w-20 '"
                      class="list-container-results"
                      :load-more="loadMore"
                      :error="error"
                      :results="results"/>
      <list-container v-if="resultsSuggestion.length > 0" title="wi-suggestions-help-center"
                      :amount-loaded="this.amount"
                      class="list-container-suggestions"
                      :classItem="'col-md-6 col-lg-3 w-20'"
                      :load-more="loadMore"
                      :results="resultsSuggestion"/>
    </div>
  </div>
</template>


<script>
import '/src/assets/base.css'

import axios from "axios";

import SearchBar from "@/components/searchBar";
import TitleBar from "@/components/titleBar";
import ListContainer from "@/components/listContainer";

export default {
  name: 'Search',
  data() {
    return {
      keywords: null,
      results: [],
      amount: 10,
      resultsSuggestion: [],
      error: false
    }
  },
  components: {
    ListContainer,
    TitleBar,
    SearchBar
  },
  methods: {
    sendSearch(value) {
      try {
        this.$Progress.start();
        this.error = false;
        axios.get(`https://intelligence.builderall.com/contents-search/` + this.lang + `/` + this.email + `/` + value).then((response) => {
          if(response.data.error || response.data.length === 0){
            this.error = true;
          }
          this.results = response.data
          this.amount = 10
          this.$Progress.finish();
        })
      } catch (e) {
        this.errors.push(e)
        this.$Progress.fail();
      }
    },
    loadMore() {
      this.$Progress.start();
      setTimeout(() => {
        this.amount += 10;
        this.$Progress.finish();
      }, 400);
    }
  },
  async created() {
    try {

      this.email = this.$route.params.email;
      this.lang = this.$route.params.lang;

      this.$i18n.locale = this.lang;

      const response = await axios.get(`https://intelligence.builderall.com/contents/` + this.lang + `/` + this.email);
      this.resultsSuggestion = response.data
    } catch (e) {
      console.log(e);
      this.errors.push(e)
    }
  }
};
</script>
<!--https://intelligence.builderall.com/contents/pt/h.cesar.silva@gmail.com-->
