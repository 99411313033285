<template>
  <div class="wizard-container container-fluid">
    <div class="container" v-if="step === 0">

      <base-steps img="create-blog.svg" title="wi-title-create-blog"
                  sub-title="wi-desc-create-blog"/>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStep">
            {{ $t('ct-start-here') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="container" v-if="step === 1">
      <title-bar title="wi-title-create-blog-name"
                 sub-title="wi-desc-create-blog-name">
        <button class="btn btn-secondary btn-back" @click="step = 0">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <stepName :handleChange="handleChange" title="wi-title-choose-name-blog"/>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStep" v-if="blogName !== ''">
            {{ $t('ct-next-step') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="container" v-if="step === 2">
      <title-bar title="wi-title-create-blog-theme"
                 sub-title="wi-desc-create-blog-theme">
        <button class="btn btn-secondary btn-back" @click="step = 1">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="row pt-5 mt-5">
        <div :class="'col-4 p-1 ' + (template === 'blank_template' ? 'active-template': '')">
          <div class="card  container-template-card" style="cursor: pointer">
            <img class="card-img-top"
                 src="https://sitebuilder-admin.omb11.com/storage/templates/125507202010055f7b17abd96b4.jpeg"
                 style="height: 232px;">
            <div class="card-body container-template-body w-100">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button class="float-left btn btn-secondary text-uppercase btn-select-template"
                        style="margin-right: 25px"
                        @click="selectTemplate('blank_template')">
                  {{ (template === 'blank_template' ? $t('wi-deselect') : $t('wi-select')) }}
                </button>
              </div>
              <span class="title-template">{{ $t('wi-blog-blank-template') }}</span>
            </div>
          </div>
        </div>
        <div :class="'col-4 p-1 ' + (template === 'template' ? 'active-template': '')">
          <div class="card  container-template-card" style="cursor: pointer">
            <img class="card-img-top" src="https://storage.builderall.com//franquias/2/18218/editor-html/5294638.jpg">
            <div class="card-body container-template-body w-100">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button class="float-left btn btn-secondary text-uppercase btn-select-template"
                        @click="selectTemplate('template')">
                  {{ (template === 'template' ? $t('wi-deselect') : $t('wi-select')) }}
                </button>
                <a class="float-left btn btn-secondary text-uppercase btn-select-template btn-select-template-2"
                   href="" target="_blank">
                  <i class="fas fa-eye"></i>
                </a>
              </div>
              <span class="title-template">{{ $t('wi-blog-blank-builderall-template') }}</span>
            </div>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStep" v-if="template !== null">
            {{ $t('ct-next-step') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="container" v-if="step === 3">
      <title-bar title="wi-title-create-blog-confirm"
                 sub-title="wi-desc-create-blog-confirm">
        <button class="btn btn-secondary btn-back" @click="step = 2">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-6 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ websiteName }}</h4>
            <small>{{ $t('wi-name-of-blog') }}</small>
          </div>
          <div class="col-6 align-self-center">
            <img class="card-img-top" :src="getThemeData().img" style="border: 1px solid lightgrey">
            <h4 class="mt-3">{{ $t(getThemeData().text) }}</h4>
            <small>{{ $t('wi-template-of-blog') }}</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-success btn-create-website" @click="createBlog">
            {{ $t('wi-create-blog') }}
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.active-template .container-template-card {
  background: #B2CEF433 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000029;
}

.active-template .container-template-card .btn-select-template {
  border-color: #0080FC;
  background: #0080FC 0% 0% no-repeat padding-box !important;
  box-shadow: 0.62px 0.79px 2px #1E120D1A;
}

.active-template .container-template-card .btn-select-template-2 {
  border-color: #0080FCA8;
  background: #0080FCA8 0% 0% no-repeat padding-box !important;
  box-shadow: 0.62px 0.79px 2px #1E120D1A;
}

</style>

<script>

import BaseSteps from "@/components/baseSteps";
import TitleBar from "@/components/titleBar";
import StepName from "@/components/stepName";
import doMagic from "@/components/doMagic";

export default {
  name: 'CreateBlog',
  data() {
    return {
      step: 0,
      blogName: '',
      template: null,

      templateData: {
        'blank_template': {
          text: 'wi-blog-blank-template',
          img: 'https://sitebuilder-admin.omb11.com/storage/templates/125507202010055f7b17abd96b4.jpeg'
        },
        'template': {
          text: 'wi-blog-blank-builderall-template',
          img: 'https://storage.builderall.com//franquias/2/18218/editor-html/5294638.jpg'
        }
      }
    }
  },
  components: {
    BaseSteps,
    TitleBar,
    StepName
  },
  methods: {

    createBlog() {

      let obj = {
        blog: true,
        blogName: this.blogName,
        templateData: this.template
      }

      doMagic(obj, this);
    },

    getThemeData() {
      return this.templateData[this.template];
    },

    selectTemplate(value) {
      if (this.template === value) {
        this.template = null
      } else {
        this.template = value;
      }
    },
    nextStep() {
      this.step += 1;

      this.amountLoaded = 6;

      this.loading = true;
    },

    handleChange(value) {
      this.blogName = value;
    },
  }
}
</script>
