<template>
  <div class="container-fluid p-5">
    <div class="row container-404 content-justify-between">
      <div class="card col-xs-12 col-md-8 mx-auto mt-5">


        <h1>404</h1>

        <h4>OPPS! PAGE NOT FOUND</h4>

        <p>Sorry, the page you're looking for doesn't exist. </p>

        <div class="mx-auto p-5">
          <button class="btn btn-primary p-3">RETURN HOME</button>
        </div>
      </div>

    </div>
  </div>
</template>


<style>

.container-404 {
  font-family: Lato, sans-serif
}

.container-404 h1 {
  font-size: 120px;
  color: #5ec3e3;
}

.container-404 h4 {
  font-size: 50px;
  font-weight: 700;
}

.container-404 p {
  font-size: 20px;
}
.container-404 button {
  border-radius: 30px;
}

</style>

<script>

import '/src/assets/base.css'


export default {
  name: 'NotFoundComponent',
  async created() {
    try {

      this.email = this.$route.params.email;
      this.lang = this.$route.params.lang;

      this.$i18n.locale = this.lang;

    } catch (e) {
      console.log(e);
      this.errors.push(e)
    }
  }
};
</script>
<!--https://intelligence.builderall.com/contents/pt/h.cesar.silva@gmail.com-->
