<template>
  <div class="row">
    <div class="col-xs-12 col-md-8 mx-auto px-5 m-4 pt-4 pb-5 container-search">
      <form v-on:submit.prevent="sendSearch()">
        <label class="text-sm-start w-100">
          <span class="search-label">{{ $t('wi-search-text') }}</span>
          <div class="input-group mt-2">
            <span>
              <font-awesome-icon :icon="search"/>
            </span>
            <input id="search-input" class="form-control form-control-lg" type="search"
                   placeholder="Busca"
                   v-model="keywords">
          </div>
        </label>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.search-label {
  text-align: left;
  font: normal normal bold 14px Lato;
  letter-spacing: 0;
  color: #656565;
  opacity: 1;
}

.container-search {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 2px 4px #0000001A;
  border-radius: 5px;
  opacity: 1;

  svg {
    margin: 15px 0 15px 20px;
    font-size: 20px;
  }

  input {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.input-group {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 0 2px #26242440;
  border-radius: 5px;
  opacity: 1;
  border: none;
}
</style>

<script>

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faSearch} from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'searchBar',
  props: {
    callback: Function,
  },
  data() {
    return {
      keywords: '',
      search: faSearch
    }
  },
  methods: {
    sendSearch() {
      this.callback(this.keywords)
    }
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>
