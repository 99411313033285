<template>
  <div class="wizard-container container-fluid">
    <div class="container" v-if="step === 0">

      <base-steps :img="initialImg" :title="initialTitle"
                  :sub-title="initialSubtitle"/>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStepFiltered">
            {{ $t('ct-start-here') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>


    <div class="container" v-if="step === 1">
      <title-bar title="wi-title-create-website-template"
                 sub-title="wi-desc-create-website-template">
        <button class="btn btn-secondary btn-back" @click="step = 0">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="row py-5 my-5">
        <span style="text-align: left">{{ $t('wi-title-choose-template') }}</span>

        <div v-show="templateSelected === null" class="col-4 p-1"
             v-for="(template, index) in templatesFilteredArray.slice(0, amountLoaded)" :key="index">
          <div class="card container-template-card">
            <img class="card-img-top" :src="template.website.thumbnail">
            <div class="card-body container-template-body w-100">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button class="float-left btn btn-secondary text-uppercase btn-select-template"
                        @click="selectTemplate(template)">
                  {{ $t('wi-select') }}
                </button>
                <a class="float-left btn btn-secondary text-uppercase btn-select-template btn-select-template-2"
                   :href="template.url" target="_blank">
                  <i class="fas fa-eye"></i>
                </a>
              </div>
              <span class="title-template">{{ template.text }}</span>
            </div>
          </div>

        </div>

        <div v-show="templateSelected === null" class="col-12 button-place pt-5"
             v-if="templatesFilteredArray.length > amountLoaded">
          <button class="load-more-button w-100" @click="loadMore"><span> {{ $t('wi-load-more') }}</span></button>
        </div>

        <div v-if="templateSelected !== null" class="col-12 p-1">
          <div class="card container-template-card">

            <grid-loader class="loader-gif" :loading="loading" color="#0d6efd" size="50px"></grid-loader>

            <iframe @load="loadIframe" style="height: 50vh" class="card-img-top"
                    :src="templateSelected.url"></iframe>
            <div class="card-body container-template-body w-100">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button class="float-left btn btn-secondary text-uppercase btn-select-template" @click="deselect">
                  {{ $t('wi-deselect') }}
                </button>
              </div>
              <span class="title-template" style="margin-left: 25px">{{ templateSelected.text }}</span>
            </div>
          </div>

          <div class="row">
            <div class="pt-5">
              <button class="btn btn-primary btn-next-step-ba" @click="nextStepTemplateSelected">
                {{ $t('ct-next-step') }}
                <i class="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="step === 2">

      <title-bar title="wi-title-create-website-template-name"
                 sub-title="wi-desc-create-website-template-name">
        <button class="btn btn-secondary btn-back" @click="backStep(1)">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <stepName :handleChange="handleChange" title="wi-title-choose-name">
        <h6 class="mt-3 w-100" style="text-align: left">Tags</h6>
        <div class="form-group tags-separator" v-for="(tag, index) in smart_tags" v-bind:key="index">
          <label class=" mt-3 w-100" style="text-align: left;">
            {{ $t(findTag(tag), tag) }}
            <input
                type="text"
                :placeholder="tag"
                @change="(e)=>changeTag(e,tag)"
                @keyup="(e)=>changeTag(e,tag)"
                :id="tag"
                class="form-control smart-tags"
                :name="`tag-${index}`">
          </label>
          <p style="text-align: left;font-size: smaller;">{{ $t('ct-example') }}: {{ $t(findTagEx(tag), tag) }}</p>
        </div>
      </stepName>


      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStep" v-if="websiteName !== ''">
            {{ $t('ct-next-step') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="container" v-if="step === 3 && finishOnWebsite">

      <title-bar title="wi-title-create-website-template-confirm"
                 sub-title="wi-desc-create-website-template-confirm">
        <button class="btn btn-secondary btn-back" @click="step = 2">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-4 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ websiteName }}</h4>
            <small>{{ $t('wi-name-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img class="card-img-top" :src="templateSelected.website.thumbnail">
            <h4 class="mt-3">{{ templateSelected.text }}</h4>
            <small>{{ $t('wi-template-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img src="@/assets/steps/nicho.svg">
            <h4 class="mt-3">{{ getCategoryName() }}</h4>
            <small>{{ $t('wi-category-of-site') }}</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-success btn-create-website" @click="createWebsite">
            {{ $t('wi-create-website') }}
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>

    </div>


    <div class="container" v-if="step === 4">

      <title-bar title="wi-title-create-website-goto"
                 sub-title="wi-desc-create-website-goto">
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-6 align-self-center">
            <a :href="url" target="_blank">
              <button class="btn btn-success btn-create-website">
                {{ $t('wi-edit-website') }}
              </button>
            </a>
          </div>

          <div class="col-6 align-self-center">
            <a :href="publishUrl" target="_blank">
              <button class="btn btn-success btn-create-website">
                {{ $t('wi-goto-website') }}
              </button>
            </a>
          </div>
        </div>
      </div>

    </div>

    <div class="container" v-if="step === 3 && !finishOnWebsite">
      <slot></slot>
    </div>
  </div>
</template>


<script>

import StepName from "@/components/stepName";
import BaseSteps from "@/components/baseSteps";
import TitleBar from '@/components/titleBar'
import GridLoader from 'vue-spinner/src/GridLoader.vue'
// import Select2 from 'vue3-select2-component';

import axios from "axios";
import doMagic from "@/components/doMagic";

require('./style.css');


export default {
  name: 'CreateWebsite',
  props: {
    initialTitle: {
      type: String,
      default: 'wi-title-create-website'
    },
    initialSubtitle: {
      type: String,
      default: 'wi-title-create-website'
    },
    initialImg: {
      type: String,
      default: 'create-website.svg'
    },
    finishOnWebsite: {
      type: Boolean,
      default: true
    },
    stepChange: {
      type: Function,
      default: null
    },
    selectedCategory: {
      default: 2
    }
  },
  data() {
    return {
      step: 0,
      amountLoaded: 12,

      categoriesArray: [],

      selectedTemplate: null,
      templatesArray: [],
      templatesFilteredArray: [],

      smart_tags: [],
      smart_tags_open: [],
      smart_tags_vales: [],

      templateSelected: null,
      loading: true,

      websiteName: '',
      loggedOffice: null,

      url: null,
      publishUrl: null
    }
  },
  components: {
    StepName,
    BaseSteps,
    TitleBar,
    // Select2,
    GridLoader
  },
  methods: {

    findTag(name) {

      let returnString = '';
      try {
        for (let smart in Object.keys(this.smart_tags_open)) {
          if (name.toLowerCase().indexOf(this.smart_tags_open[smart].name.toLowerCase()) > -1) {
            returnString = this.smart_tags_open[smart].description;
          }
        }
      } catch (e) {
        returnString = '';
      }

      return returnString;
    },
    findTagEx(name) {

      let returnString = '';
      try {
        for (let smart in Object.keys(this.smart_tags_open)) {
          if (name.toLowerCase().indexOf(this.smart_tags_open[smart].name.toLowerCase()) > -1) {
            returnString = this.smart_tags_open[smart].example;
          }
        }
      } catch (e) {
        returnString = '';
      }

      return returnString;
    },

    changeUrlButton(url, publishUrl) {

      this.nextStep();

      console.log(url, publishUrl);

      this.url = url;
      this.publishUrl = publishUrl;
    },

    handleChange(value) {
      this.websiteName = value;
    },

    createWebsite() {

      let obj = {
        website: true,
        websiteName: this.websiteName,
        template_id: this.templateSelected.website.encrypted_id,
        smart_tags: Object.assign({}, this.smart_tags_vales)
      }
      console.log(obj);

      doMagic(obj, this);
    },

    getCategoryName() {
      let vm = this;
      return this.categoriesArray.find(item => item.id == vm.selectedCategory).text;
    }
    ,

    deselect() {
      this.templateSelected = null;
      this.loading = true;
    }
    ,
    selectTemplate(template) {
      this.templateSelected = template;
    }
    ,
    loadMore() {
      this.$Progress.start();
      setTimeout(() => {
        this.amountLoaded += 12;
        this.$Progress.finish();
      }, 400);
    }
    ,
    backStep(step) {
      this.step = step;
      this.templateSelected = null;

    }
    ,
    nextStep() {
      this.step += 1;

      this.amountLoaded = 12;

      this.loading = true;

      console.log(this.stepChange)

      if (this.stepChange !== null) {
        this.stepChange(this.step);
      }
    }
    ,
    loadIframe() {
      this.loading = false;
    }
    ,
    nextStepFiltered() {
      this.nextStep();

      let vm = this;

      this.templatesFilteredArray = this.templatesArray.filter((item) => {
        return item.category_id === parseInt(vm.selectedCategory);
      })
    },
    nextStepTemplateSelected() {
      this.nextStep();

      let parser = new DOMParser();

      axios.get(this.templateSelected.url).then(response => {
        this.smart_tags = parser.parseFromString(response.data, "text/html")
            .querySelector('.seo-power-texts')
            .innerHTML
            .match(/\[.+?\]/g);
      })
    },
    changeTag(e, tag) {
      this.smart_tags_vales[tag] = e.target.value;
    }
  },
  async created() {

    if(window.location.search.split('lang=')){
      this.$i18n.locale = window.location.search.split('lang=')[1];
    }

    await axios.get('https://cheetah-api.builderall.com/template/open').then(response => {
      this.categoriesArray = response.data.categories.filter((item) => {
        return item.show === 1;
      })
      this.templatesArray = response.data.templates;
    })
    await axios.get('https://cheetah-api.builderall.com/auto-tags/open').then(response => {
      this.smart_tags_open = JSON.parse(JSON.stringify(response.data));
    })
  }
}
</script>
