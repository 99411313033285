<template>
  <div class="row list-container">
    <div class="col-12 pt-5 mb-2">
      <h4>{{ $t(this.title) }}</h4>
    </div>
    <list-item v-for="result in results.slice(0, amountLoaded)" :key="result.id" :data="result"
               :changeOptions="changeOptions" :classItem="classItem"/>
    <div class="col-12 button-place pt-5" v-if="results.length > amountLoaded">
      <button class="load-more-button w-100" @click="loadMore"><span> {{ $t('wi-load-more') }}</span></button>
    </div>
    <div v-if="this.error" class="alert alert-warning col-12 ">{{ $t('wi-help-center-no-results') }}</div>
  </div>
</template>

<style lang="scss">

.list-container h4 {
  text-align: left;
  font: normal normal 800 25px Mulish;
  letter-spacing: 0;
  color: #0080FC;
}

.load-more-button {
  background: #FCFCFC 0 0 no-repeat padding-box;
  border-radius: 3px;
  opacity: 0.74;
  padding: 25px;
  border: none;

  width: calc(100% - 40px);

  span {
    text-align: center;
    font: normal normal bold 16px/14px Mulish;
    letter-spacing: 0;
    color: #737373;
    opacity: 1;
  }
}

.button-place {
  margin-left: 20px;
  width: calc(100% - 60px) !important;
}

.alert-warning {
  font-size: 20px;
}

</style>

<script>
import ListItem from "@/components/listItem";

export default {
  name: 'listContainer',
  components: {ListItem},
  props: {
    title: String,
    results: Array,
    amountLoaded: Number,
    loadMore: Function,
    classItem: String,
    error: Boolean,
    changeOptions: Function
  },
  created() {
    console.log(this)
  },
  updated() {
    console.log(this)
  }
};
</script>
