<template>
  <create-website initialTitle="wi-title-create-leads-area"
                  initialSubtitle="wi-desc-create-leads-area"
                  initialImg="leads.svg"
                  :finishOnWebsite="false"
                  :stepChange="triggerChildMethod"
                  ref="createWebsite">

    <div class="container" v-if="stepLeads === 4">
      <title-bar title="wi-title-create-leads-type"
                 class=" mb-5"
                 sub-title="wi-desc-create-leads-type">
        <button class="btn btn-secondary btn-back" @click="backStep(3)">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="row">
        <div class="card card-input w-50 mx-auto">
          <div class="btn-group mx-auto">
            <button class="btn btn-primary">{{ $t('ct-choose-list') }}</button>
            <button class="btn btn-success">{{ $t('ct-create-list') }}</button>
          </div>
        </div>
      </div>


      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStepLeads" v-if="leadsName !== ''">
            {{ $t('ct-next-step') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

    </div>


    <div class="container" v-if="stepLeads === 5">

      <title-bar title="wi-title-create-leads-template-confirm"
                 sub-title="wi-desc-create-leads-template-confirm">
        <button class="btn btn-secondary btn-back" @click="backStepLeads(4)">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-4 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ getDataFromWebsite('websiteName') }}</h4>
            <small>{{ $t('wi-name-of-site') }}</small>
            <br/>
            <img src="@/assets/steps/nicho.svg">
            <h4 class="mt-3">{{ $refs.createWebsite.getCategoryName() }}</h4>
            <small>{{ $t('wi-category-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img class="card-img-top"
                 :src="'https://sitebuilder-admin.omb11.com/storage/'+getDataFromWebsite('templateSelected').thumbnail">
            <h4 class="mt-3">{{ getDataFromWebsite('templateSelected').text }}</h4>
            <small>{{ $t('wi-template-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ leadsName }}</h4>
            <small>{{ $t('wi-name-of-leads') }}</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-success btn-create-website" @click="createLeads">
            {{ $t('wi-create-leads') }}
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>

    </div>

  </create-website>
</template>

<style>
.title-blue {
  color: #0F7BFF;
  font: normal normal 500 20px/25px Mulish;
  letter-spacing: 0;
  opacity: 1;
}

br {
  display: block;
  margin: 10px 0;
  content: " ";
}

.desc-leads {
  text-align: center;
  font: normal normal normal 14px/16px Mulish;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}

</style>

<script>

import createWebsite from '../CreateWebsite/index'
import titleBar from "@/components/titleBar";

export default {
  name: 'LeadsArea',
  data() {
    return {
      stepLeads: 0,
      type: null,
      leadsName: '',
      leadsColor: '',
      leadsDesc: '',
    }
  },
  components: {
    createWebsite,
    titleBar
  },
  methods: {
    getDataFromWebsite(param) {

      console.log(this.$refs.createWebsite, param);

      return this.$refs.createWebsite[param];
    },
    createLeads() {

    },
    handleChange(value) {
      this.leadsName = value;
    },
    handleChangeDesc(value) {
      this.leadsDesc = value;
    },
    handleChangeColor(value) {
      this.leadsColor = value;
    },
    backStepLeads(step) {
      this.stepLeads = step;
    },
    backStep(step) {
      this.$refs.createWebsite.step = step;
      this.stepLeads = step;
    },
    triggerChildMethod(step) {
      this.stepLeads = step;
    },
    nextStepLeads(type) {
      this.type = type;

      this.stepLeads = this.stepLeads + 1;
    },
    nextStepW() {
      this.stepLeads = this.stepLeads + 1;
    }
  },
}
</script>
