import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import VueProgressBar from "@aacassandra/vue3-progressbar";
import {createI18n} from 'vue-i18n'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

const messages = (() => {
	let locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	let messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
})();

const i18n = createI18n({
	locale: 'us',
	fallbackLocale: 'us',
	messages,
	// missing(locale, path) {
	// 	console.log(path);
	// },
})

const options = {
	color: "#0080FC",
	failedColor: "#874b4b",
	thickness: "5px",
	transition: {
		speed: "0.2s",
		opacity: "0.6s",
		termination: 300,
	},
	autoRevert: true,
	inverse: false,
};

createApp(App)
	.use(i18n)
	.use(VueProgressBar, options)
	.use(router)
	.mount('#app')
