<template>
  <create-website initialTitle="wi-title-create-webinar"
                  initialSubtitle="wi-desc-create-webinar"
                  initialImg="create-webinar.svg"
                  :finishOnWebsite="false"
                  :selectedCategory="7"
                  :stepChange="triggerChildMethod"
                  ref="createWebsite">
    <div class="wizard-container container-fluid">

<!--      <div class="container" v-if="stepWebinar === 3">-->
<!--        <base-steps img="create-webinar.svg" title="wi-title-create-webinar"-->
<!--                    sub-title="wi-desc-create-webinar"/>-->

<!--        <div class="row">-->
<!--          <div class="pt-5">-->
<!--            <button class="btn btn-primary btn-next-step-ba" @click="nextStepW">-->
<!--              {{ $t('ct-start-here') }}-->
<!--              <i class="fas fa-arrow-right"></i>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

<!--      <div class="container" v-if="stepWebinar === 3">-->
<!--        <title-bar title="wi-title-create-webinar-type"-->
<!--                   sub-title="wi-desc-create-webinar-type">-->
<!--          <button class="btn btn-secondary btn-back" @click="backStep(3)">-->
<!--            <i class="fas fa-arrow-left"></i>-->
<!--            {{ $t('ct-wizard-back') }}-->
<!--          </button>-->
<!--        </title-bar>-->

<!--        <div class="row no-gutters mt-5 pt-5">-->
<!--          <div class="col-4">-->
<!--            <div class="card p-5">-->
<!--              <h4 class="title-blue">{{ $t('wi-webinar-live-chat') }}</h4>-->
<!--              <small class="border-top border-bottom pt-2 pb-2 mb-4">{{ $t('wi-webinar-select') }}</small>-->
<!--              <h6 class="desc-webinar border-bottom pb-4" v-html="$t('wi-webinar-chat-desc')"></h6>-->
<!--              <button class="btn btn-primary mt-3" @click="nextStepWebinar('by_time')">{{ $t('wi-select') }}</button>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-4">-->
<!--            <div class="card p-5">-->
<!--              <h4 class="title-blue">{{ $t('wi-webinar-meeting-room') }}</h4>-->
<!--              <small class="border-top border-bottom pt-2 pb-2 mb-4">{{ $t('wi-webinar-select') }}</small>-->
<!--              <h6 class="desc-webinar border-bottom pb-4" v-html="$t('wi-webinar-meeting-room-desc')"></h6>-->
<!--              <a href="https://office.builderall.com/us/office/new-webinar">-->
<!--                <button class="btn btn-primary mt-3 w-100">-->
<!--                  {{ $t('wi-select') }}-->
<!--                </button>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->

<!--          <div class="col-4">-->
<!--            <div class="card p-5">-->
<!--              <h4 class="title-blue">{{ $t('wi-webinar-evergreen') }}</h4>-->
<!--              <small class="border-top border-bottom pt-2 pb-2 mb-4">{{ $t('wi-webinar-select') }}</small>-->
<!--              <h6 class="desc-webinar border-bottom pb-4" v-html="$t('wi-webinar-evergreen-desc')"></h6>-->
<!--              <button class="btn btn-primary mt-3" @click="nextStepWebinar('on_demand')">{{ $t('wi-select') }}</button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="container" v-if="stepWebinar === 4">-->
<!--        <title-bar title="wi-title-create-webinar-name"-->
<!--                   sub-title="wi-desc-create-webinar-name">-->
<!--          <button class="btn btn-secondary btn-back" @click="backStepWebinar(4)">-->
<!--            <i class="fas fa-arrow-left"></i>-->
<!--            {{ $t('ct-wizard-back') }}-->
<!--          </button>-->
<!--        </title-bar>-->

<!--        <stepName :handleChange="handleChange" title="wi-title-choose-name-webinar"/>-->

<!--        <div class="row">-->
<!--          <div class="pt-5">-->
<!--            <button class="btn btn-primary btn-next-step-ba" @click="nextStepW" v-if="webinarName !== ''">-->
<!--              {{ $t('ct-next-step') }}-->
<!--              <i class="fas fa-arrow-right"></i>-->
<!--            </button>-->
<!--          </div>-->
<!--        </div>-->

<!--      </div>-->

      <div class="container" v-if="stepWebinar === 3">

        <title-bar title="wi-title-create-webinar-template-confirm"
                   sub-title="wi-desc-create-webinar-template-confirm">
          <button class="btn btn-secondary btn-back" @click="backStepWebinar(2)">
            <i class="fas fa-arrow-left"></i>
            {{ $t('ct-wizard-back') }}
          </button>
        </title-bar>

        <div class="card my-5 py-5 card-input">
          <div class="row py-5">
            <!--          <div class="col-4 align-self-center">-->
            <!--            <img src="@/assets/steps/name_of.svg">-->
            <!--            <h4 class="mt-3">{{ getDataFromWebsite('websiteName') }}</h4>-->
            <!--            <small>{{ $t('wi-name-of-site') }}</small>-->
            <!--            <br/>-->
            <!--            <img src="@/assets/steps/nicho.svg">-->
            <!--            <h4 class="mt-3">{{ $refs.createWebsite.getCategoryName() }}</h4>-->
            <!--            <small>{{ $t('wi-category-of-site') }}</small>-->
            <!--          </div>-->
            <!--          <div class="col-4 align-self-center">-->
            <!--            <img class="card-img-top"-->
            <!--                 :src="'https://sitebuilder-admin.omb11.com/storage/'+getDataFromWebsite('templateSelected').thumbnail">-->
            <!--            <h4 class="mt-3">{{ getDataFromWebsite('templateSelected').text }}</h4>-->
            <!--            <small>{{ $t('wi-template-of-site') }}</small>-->
            <!--          </div>-->
            <div class="col-12 align-self-center">
              <img src="@/assets/steps/name_of.svg">
              <h4 class="mt-3">{{ webinarName }}</h4>
              <small>{{ $t('wi-name-of-webinar') }}</small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="pt-5">
            <button class="btn btn-success btn-create-website" @click="createWebinar">
              {{ $t('wi-create-webinar') }}
              <i class="fas fa-save"></i>
            </button>
          </div>
        </div>

      </div>

      <div class="container" v-if="stepWebinar === 4">

        <title-bar title="wi-title-create-website-goto"
                   sub-title="wi-desc-create-website-goto">
        </title-bar>

        <div class="card my-5 py-5 card-input">
          <div class="row py-5">
            <div class="col-6 align-self-center">
              <a :href="url" target="_blank">
                <button class="btn btn-success btn-create-website">
                  {{ $t('wi-edit-website') }}
                </button>
              </a>
            </div>

            <div class="col-6 align-self-center">
              <a :href="publishUrl" target="_blank">
                <button class="btn btn-success btn-create-website">
                  {{ $t('wi-goto-website') }}
                </button>
              </a>
            </div>
          </div>
        </div>

      </div>

      <div class="container" v-if="stepWebinar === 5">

        <title-bar title="wi-title-create-website-goto"
                   sub-title="wi-desc-create-website-goto">
        </title-bar>

        <div class="card my-5 py-5 card-input">
          <div class="row py-5">
            <div class="col-6 align-self-center">
              <a :href="url" target="_blank">
                <button class="btn btn-success btn-create-website">
                  {{ $t('wi-edit-website') }}
                </button>
              </a>
            </div>

            <div class="col-6 align-self-center">
              <a :href="publishUrl" target="_blank">
                <button class="btn btn-success btn-create-website">
                  {{ $t('wi-goto-website') }}
                </button>
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>

  </create-website>
</template>

<style>
.title-blue {
  color: #0F7BFF;
  font: normal normal 500 20px/25px Mulish;
  letter-spacing: 0;
  opacity: 1;
}

br {
  display: block;
  margin: 10px 0;
  content: " ";
}

.desc-webinar {
  text-align: center;
  font: normal normal normal 14px/16px Mulish;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}

</style>

<script>

import createWebsite from '../CreateWebsite/index'
import titleBar from "@/components/titleBar";
// import stepName from "@/components/stepName";
import doMagic from "@/components/doMagic";
// import baseSteps from "@/components/baseSteps";

export default {
  name: 'CreateWebinar',
  data() {
    return {
      stepWebinar: 0,
      // stepWebinar: 3,
      type: null,
      webinarName: '',

      url:null,
      publishUrl:null,
    }
  },
  components: {
    createWebsite,
    // baseSteps,
    titleBar,
    // stepName
  },
  methods: {
    // getDataFromWebsite(param) {

    // console.log(this.$refs.createWebsite, param);

    // return this.$refs.createWebsite[param];
    // },
    changeUrlButton(url, publishUrl) {

      this.nextStepW();

      console.log(url, publishUrl);

      this.url = url;
      this.publishUrl = publishUrl;
    },

    createWebinar() {

      let obj = {
        webinar: true,
        webinarName: this.webinarName,
        type: this.type,

        smart_tags: Object.assign({}, this.$refs.createWebsite.smart_tags_vales),
        websiteName: this.$refs.createWebsite.websiteName,
        template_id: this.$refs.createWebsite.templateSelected.website.encrypted_id
      }

      doMagic(obj, this);

    },
    handleChange(value) {
      this.webinarName = value;
    },
    backStepWebinar(step) {
      this.stepWebinar = step;
    },
    backStep(step) {
      // this.$refs.createWebsite.step = step;
      this.stepWebinar = step;
    },
    triggerChildMethod(step) {
      this.stepWebinar = step;
    },
    nextStepWebinar(type) {
      this.type = type;

      this.stepWebinar = this.stepWebinar + 1;
    },
    nextStepW() {
      this.stepWebinar = this.stepWebinar + 1;
    }
  },
}
</script>
