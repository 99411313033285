<template>
  <div class="wizard-container container-fluid">
    <div class="container" v-if="step === 0">

      <base-steps img="create-magazine.svg" title="wi-title-create-magazine"
                  sub-title="wi-desc-create-magazine"/>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStep">
            {{ $t('ct-start-here') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="container" v-if="step === 1">
      <title-bar title="wi-title-create-magazine-name"
                 sub-title="wi-desc-create-magazine-name">
        <button class="btn btn-secondary btn-back" @click="step = 0">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <stepName :handleChange="handleChange" title="wi-title-choose-name-magazine"/>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStep" v-if="magazineName !== ''">
            {{ $t('ct-next-step') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="container" v-if="step === 2">
      <title-bar title="wi-title-create-magazine-template"
                 sub-title="wi-desc-create-magazine-template">
        <button class="btn btn-secondary btn-back" @click="step = 1">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="row">

        <div v-show="templateSelected === null" class="col-4 p-1"
             v-for="(template, index) in templatesArray.slice(0, amountLoaded)" :key="index">

          <div class="card container-template-card">
            <!--          <img class="card-img-top" :src="'https://sitebuilder-admin.omb11.com/storage/'+template.thumbnail">-->
            <div class="card-body container-template-body w-100">
              <div class="btn-group" role="group" aria-label="Basic example">
                <button class="float-left btn btn-secondary text-uppercase btn-select-template"
                        @click="selectTemplate(template)">
                  {{ $t('wi-select') }}
                </button>
                <!--              <a class="float-left btn btn-secondary text-uppercase btn-select-template btn-select-template-2"-->
                <!--                 :href="'https://'+template.title + '.cheetah.builderall.com'" target="_blank">-->
                <!--                <i class="fas fa-eye"></i>-->
                <!--              </a>-->
              </div>
              <span class="title-template">{{ template.title }}</span>
              <span class="title-template description">{{ template.description }}</span>
            </div>
          </div>
        </div>
        <div v-show="templateSelected === null" class="col-12 button-place pt-5"
             v-if="templatesArray.length > amountLoaded">
          <button class="load-more-button w-100" @click="loadMore"><span> {{ $t('wi-load-more') }}</span></button>
        </div>
      </div>

    </div>

    <div class="container" v-if="step === 3">
      <title-bar title="wi-title-create-magazine-confirm"
                 sub-title="wi-desc-create-magazine-confirm">
        <button class="btn btn-secondary btn-back" @click="step = 2">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-6 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ magazineName }}</h4>
            <small>{{ $t('wi-name-of-magazine') }}</small>
          </div>
          <div class="col-6 align-self-center">
<!--            <img class="card-img-top" :src="getThemeData().thumbnail" style="border: 1px solid lightgrey">-->
            <h4 class="mt-3">{{ templateSelected.title }}</h4>
            <small>{{ $t('wi-template-of-magazine') }}</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-success btn-create-website" @click="createMagazine">
            {{ $t('wi-create-magazine') }}
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

.title-template.description {
  text-align: left;
  font: normal normal normal 12px/20px Mulish;
  letter-spacing: 0;
  color: #00050a;
  opacity: 1;
  display: block;
}

</style>

<script>

import BaseSteps from "@/components/baseSteps";
import TitleBar from "@/components/titleBar";
import StepName from "@/components/stepName";
import axios from "axios";
import doMagic from "@/components/doMagic";

export default {
  name: 'CreateMagazine',
  data() {
    return {
      step: 0,
      magazineName: '',
      templateSelected: null,
      templatesArray: [],
      amountLoaded: 12
    }
  },
  components: {
    BaseSteps,
    TitleBar,
    StepName
  },
  methods: {

    createMagazine(){

      let obj = {
        magazine:true,
        magazineName: this.magazineName,
        templateData: this.templateSelected
      }

      doMagic(obj, this);

    },

    getThemeData() {
      return this.templateSelected;
    },

    selectTemplate(value) {
      if (this.templateSelected === value) {
        this.templateSelected = null
      } else {
        this.templateSelected = value;
      }

      this.nextStep();
    },
    nextStep() {
      this.step += 1;

      this.amountLoaded = 12;

      this.loading = true;
    },

    loadMore() {
      this.$Progress.start();
      setTimeout(() => {
        this.amountLoaded += 12;
        this.$Progress.finish();
      }, 400);
    },

    handleChange(value) {
      this.magazineName = value;
    },
  },
  async created() {
    await axios.get('https://api-magazine.omb11.com/public/templates').then(response => {

      console.log(response);

      // this.categoriesArray = response.data.categories.filter((item) => {
      //   return item.show === 1;
      // })
      this.templatesArray = response.data;
    })
  }
}
</script>
