<template>
  <create-website :initialTitle="'wi-title-'+(digital?'digital':'physical')+'-store'"
                  :initialSubtitle="'wi-desc-'+(digital?'digital':'physical')+'-store'"
                  :initialImg="(digital?'digital':'physical')+'-store.svg'"
                  :finishOnWebsite="false"
                  :selectedCategory="(digital?5:4)"
                  :stepChange="triggerChildMethod"
                  ref="createWebsite">

    <div class="container" v-if="stepStore === 3">
      <title-bar title="wi-title-create-store-product"
                 sub-title="wi-desc-create-store-product">
        <button class="btn btn-secondary btn-back" @click="backStep(3)">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="row">
        <div class="col-xs-12 col-md-8 mx-auto pb-5 m-5">
          <div class="card">
            <div class="card-header text-left">
              {{ $t('wi-title-create-product') }}
            </div>
            <div class="card-body product-area" style="max-width: 100%">
              <label class="w-75 p-1">
                {{ $t('wi-title-create-product-name') }}
                <input class="form-control" v-model="productName"/>
              </label>
              <label class="w-25 p-1">
                {{ $t('wi-title-create-product-price') }}
                <input class="form-control" v-model="productPrice"/>
              </label>
              <label class="w-100 p-1">
                {{ $t('wi-title-create-product-description') }}
                <input class="form-control" v-model="productDescription"/>
              </label>
              <label class="w-100 p-1 mt-3 input-img-area">
                <p class="uploader">
                  <input @change="uploadImage" ref="inputImg" type="file" name="photo" accept="image/*">
                </p>
                <img :src="imageSrc" class="image" v-if="imageSrc!==null" alt="checkout-image">
                <div v-else class="d-flex h-100">
                  <div class="w-100 justify-content-center align-self-center" onclick="this.$refs.inputImg.click()">
                    <img alt="upload" src="@/assets/upload.svg">
                    <p>{{ $t('wi-upload-img') }}</p>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-primary btn-next-step-ba" @click="nextStepP" v-if="productName !== ''">
            {{ $t('ct-next-step') }}
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="container" v-if="stepStore === 4">

      <title-bar :title="'wi-title-create-store-confirm'+(digital?'-digital':'')"
                 :sub-title="'wi-desc-create-store-confirm'+(digital?'-digital':'')">
        <button class="btn btn-secondary btn-back" @click="backStepP(4)">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-4 align-self-center">
            <img src="@/assets/steps/name_of.svg">
            <h4 class="mt-3">{{ getDataFromWebsite('websiteName') }}</h4>
            <small>{{ $t('wi-name-of-site') }}</small>
            <br/>
            <img src="@/assets/steps/nicho.svg">
            <h4 class="mt-3">{{ $refs.createWebsite.getCategoryName() }}</h4>
            <small>{{ $t('wi-category-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img class="card-img-top"
                 :src="'https://sitebuilder-admin.omb11.com/storage/'+getDataFromWebsite('templateSelected').thumbnail">
            <h4 class="mt-3">{{ getDataFromWebsite('templateSelected').text }}</h4>
            <small>{{ $t('wi-template-of-site') }}</small>
          </div>
          <div class="col-4 align-self-center">
            <img :src="imageSrc">
            <h4 class="mt-3">{{ productName }}</h4>
            <small>{{ $t('wi-name-of-product') }}</small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="pt-5">
          <button class="btn btn-success btn-create-website" @click="createStore">
            {{ $t('wi-create-store') }}
            <i class="fas fa-save"></i>
          </button>
        </div>
      </div>

    </div>

    <div class="container" v-if="stepStore === 5">

      <title-bar title="wi-title-create-website-goto"
                 sub-title="wi-desc-create-website-goto">
      </title-bar>

      <div class="card my-5 py-5 card-input">
        <div class="row py-5">
          <div class="col-6 align-self-center">
            <a :href="url" target="_blank">
              <button class="btn btn-success btn-create-website">
                {{ $t('wi-edit-website') }}
              </button>
            </a>
          </div>

          <div class="col-6 align-self-center">
            <a :href="publishUrl" target="_blank">
              <button class="btn btn-success btn-create-website">
                {{ $t('wi-goto-website') }}
              </button>
            </a>
          </div>
        </div>
      </div>

    </div>

  </create-website>
</template>

<style lang="scss">
.input-img-area {
  background: #FAFBFC 0 0 no-repeat padding-box;
  border: 2px dashed #E2E4E9;
  border-radius: 12px;
  opacity: 1;

  height: 414px;

  p.uploader {
    display: none;
  }
}

.product-area {
  label:not(.input-img-area) {
    text-align: left;
  }
}

</style>

<script>
import CreateWebsite from "../CreateWebsite/index";
import titleBar from "@/components/titleBar";
import doMagic from "@/components/doMagic";

export default {
  name: 'PhysicalStore',
  data() {
    return {
      stepStore: 0,
      type: null,
      imageSrc: null,
      productName: '',
      productPrice: '',
      productDescription: '',
      digital: false,

      url: null,
      publishUrl: null
    }
  },
  components: {
    CreateWebsite,
    titleBar
  },
  methods: {
    createStore() {

      let obj = {
        checkout: true,
        imageSrc: this.imageSrc,
        productName: this.productName,
        productPrice: this.productPrice,
        productDescription: this.productDescription,
        type: this.digital,

        smart_tags: Object.assign({}, this.$refs.createWebsite.smart_tags_vales),
        websiteName: this.$refs.createWebsite.websiteName,
        template_id: this.$refs.createWebsite.templateSelected.website.encrypted_id
      }

      doMagic(obj, this);

    },

    changeUrlButton(url, publishUrl) {

      this.nextStepP();

      console.log(url, publishUrl);

      this.url = url;
      this.publishUrl = publishUrl;
    },

    getDataFromWebsite(param) {
      return this.$refs.createWebsite[param];
    },
    triggerChildMethod(step) {
      this.stepStore = step;
    },
    backStep(step) {
      this.$refs.createWebsite.step = step;
      this.stepStore = step;
    },
    backStepP(step) {
      this.stepStore = step;
    },
    uploadImage: function () {
      let vm = this;
      let file = document
          .querySelector('input[type=file]')
          .files[0];
      let reader = new FileReader();
      reader.onload = function (e) {
        vm.imageSrc = e.target.result
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);
    },
    nextStepP() {
      this.stepStore = this.stepStore + 1;
    }
  },
  created() {
    this.digital = this.$attrs.digital;
  }
};
</script>
