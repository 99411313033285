<template>
  <div class="wizard-container container-fluid initial-container">
    <div class="container">
      <title-bar title="wi-title-initial-center"
                 sub-title="wi-desc-initial-center">
        <button class="btn btn-secondary btn-back" v-if="canBack" @click="changeData">
          <i class="fas fa-arrow-left"></i>
          {{ $t('ct-wizard-back') }}
        </button>
      </title-bar>

      <div class="row list-container mt-5">
        <div class="list-container-col my-3 card-body" v-for="result in resultsSuggestion" :key="result.id"
             @click="redirects(result)">
          <div class="list-item-container-text-title">{{ result.t === true ? $t(result.title) : result.title }}</div>
          <br/><br/>
          <img class="list-item-container-img" :src="getImageData(result)" alt="data image"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>


.list-container-col {
  width: 255px;
  height: 272px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;

  margin: 40px;
  padding: 25px;

  cursor: pointer;

  &:hover {

    box-shadow: 0px 3px 5px #00000026;
  }

  .list-item-container-text-title {
    text-align: center;
    font: normal normal 800 20px/25px Mulish;
    letter-spacing: 0;
    color: #0F7BFF;
    opacity: 1;
  }

  br + br {
    margin-top: 10px;
    height: 20px;
    border-top: 1px solid #EBEBEC;
  }
}

@media (max-width: 1000px) {
  .list-container-col {
    max-width: 100% !important;
  }
}
</style>

<style lang="scss">

.float-left {
  float: left !important;
}

.btn-back {

  background: #F8F8F8 0 0 no-repeat padding-box !important;
  box-shadow: 0 1px 1px #1E120D1A !important;
  border-radius: 8px !important;
  opacity: 1 !important;

  border: none !important;

  text-align: right !important;
  font: normal normal bold 16px/27px Mulish !important;
  letter-spacing: 0 !important;
  color: #727880 !important;


  padding: 20px 30px !important;
}

</style>

<script>
import '/src/assets/base.css'

import TitleBar from "@/components/titleBar";
import axios from "axios";

export default {
  name: 'Initial',
  data() {
    return {
      canBack: false,
      returnData: [],
      resultsOptions: [],
      resultsSuggestion: [
        {
          id: 11,
          type: 'router',
          title: 'wi-wizard-create-website',
          imgComponent: 'website.svg',
          t: true,
          router: 'create-website'
        },
        // {
        //   id: 12,
        //   type: 'router',
        //   title: 'wi-wizard-create-blog',
        //   imgComponent: 'blog.svg',
        //   t: true,
        //   router: 'create-blog'
        // },
        {
          id: 21,
          title: 'wi-wizard-create-store-physical-products',
          type: 'router',
          router: 'physical-store',
          imgComponent: 'physical-product.svg',
          t: true,
        },
        // {
        //   id: 52,
        //   title: 'wi-wizard-create-leads-funnel',
        //   type: 'router',
        //   imgComponent: 'leads.svg',
        //   t: true,
        //   router: 'leads-funnel'
        // },
        {
          id: 25,
          type: 'router',
          title: 'wi-wizard-create-webinar',
          imgComponent: 'webinar.svg',
          router: 'create-webinar',
          t: true,
        },
        {
          id: 21,
          title: 'wi-wizard-create-store-digital-products',
          type: 'router',
          router: 'digital-store',
          imgComponent: 'digital-product.svg',
          t: true,
        },
        {
          id: 35,
          title: 'wi-wizard-create-website-with-members-area',
          type: 'router',
          router: 'members',
          imgComponent: 'members.svg',
          t: true,
        },
        // {
        //   id: 27,
        //   title: 'wi-wizard-create-work-with-builderall',
        //   type: 'router',
        //   imgComponent: 'work.svg',
        //   router: 'work-with-builderall',
        //   t: true,
        // },
        // {
        //   id: 1,
        //   title: 'wi-wizard-presence',
        //   type: 'wizard',
        //   imgComponent: 'presence.svg',
        //   t: true,
        //   options: [
        //     {
        //       id: 11,
        //       type: 'router',
        //       title: 'wi-wizard-create-website',
        //       imgComponent: 'website.svg',
        //       t: true,
        //       router: 'create-website'
        //     },
        //     {
        //       id: 12,
        //       type: 'router',
        //       title: 'wi-wizard-create-blog',
        //       imgComponent: 'blog.svg',
        //       t: true,
        //       router: 'create-blog'
        //     },
        //   ]
        // },
        // {
        //   id: 2,
        //   title: 'wi-wizard-sales',
        //   type: 'wizard',
        //   imgComponent: 'sales.svg',
        //   t: true,
        //   options: [
        //     {
        //       id: 21,
        //       title: 'wi-wizard-create-store-physical-products',
        //       type: 'router',
        //       router: 'physical-store',
        //       imgComponent: 'physical-product.svg',
        //       t: true,
        //     },
        //     // {
        //     //   id: 22, title: 'wi-wizard-create-sales-page', t: true,
        //     // },
        //     // {
        //     //   id: 23, title: 'wi-wizard-create-leads-funnel', t: true,
        //     // },
        //     // {
        //     //   id: 24, title: 'wi-wizard-create-sales-funnel', t: true,
        //     // },
        //     {
        //       id: 25,
        //       type: 'router',
        //       title: 'wi-wizard-create-webinar',
        //       imgComponent: 'webinar.svg',
        //       router: 'create-webinar',
        //       t: true,
        //     },
        //     {
        //       id: 26,
        //       type: 'router',
        //       title: 'wi-wizard-create-email-automations',
        //       imgComponent: 'automation.svg',
        //       router: 'automations',
        //       t: true,
        //     },
        //     {
        //       id: 27,
        //       title: 'wi-wizard-create-work-with-builderall',
        //       type: 'router',
        //       imgComponent: 'work.svg',
        //       router: 'work-with-builderall',
        //       t: true,
        //     },
        //     {
        //       id: 28,
        //       type: 'router',
        //       title: 'wi-wizard-create-booking-funnel',
        //       imgComponent: 'booking-funnel.svg',
        //       router: 'booking',
        //       t: true,
        //     },
        //   ]
        // },
        // {
        //   id: 3,
        //   title: 'wi-wizard-products',
        //   type: 'wizard',
        //   imgComponent: 'products.svg',
        //   t: true,
        //   options: [
        //     {
        //       id: 31,
        //       title: 'wi-wizard-create-website',
        //       type: 'router',
        //       router: 'create-website',
        //       imgComponent: 'website.svg',
        //       t: true,
        //     },
        //     {
        //       id: 32,
        //       type: 'router',
        //       title: 'wi-wizard-create-webinar',
        //       imgComponent: 'webinar.svg',
        //       t: true,
        //       router: 'create-webinar'
        //     },
        //     {
        //       id: 33,
        //       title: 'wi-wizard-create-store-physical-products',
        //       type: 'router',
        //       router: 'physical-store',
        //       imgComponent: 'physical-product.svg',
        //       t: true,
        //     },
        //     // {
        //     //   id: 34, title: 'wi-wizard-create-sales-page', t: true,
        //     // },
        //     {
        //       id: 35,
        //       title: 'wi-wizard-create-website-with-members-area',
        //       type: 'router',
        //       router: 'members',
        //       imgComponent: 'members.svg',
        //       t: true,
        //     },
        //     {
        //       id: 36,
        //       title: 'wi-wizard-create-store-digital-products',
        //       type: 'router',
        //       router: 'digital-store',
        //       imgComponent: 'digital-product.svg',
        //       t: true,
        //     },
        //   ]
        // },
        // {
        //   id: 4,
        //   title: 'wi-wizard-authority',
        //   type: 'wizard',
        //   imgComponent: 'authority.svg',
        //   t: true,
        //   options: [
        //     {
        //       id: 41,
        //       title: 'wi-wizard-create-website',
        //       type: 'router',
        //       router: 'create-website',
        //       imgComponent: 'website.svg',
        //       t: true,
        //     },
        //     {
        //       id: 42,
        //       type: 'router',
        //       title: 'wi-wizard-create-blog',
        //       imgComponent: 'blog.svg',
        //       t: true,
        //       router: 'create-blog'
        //     },
        //     {
        //       id: 43,
        //       type: 'router',
        //       title: 'wi-wizard-create-webinar',
        //       imgComponent: 'webinar.svg',
        //       t: true,
        //       router: 'create-webinar'
        //     },
        //     {
        //       id: 44,
        //       title: 'wi-wizard-create-magazine',
        //       type: 'router',
        //       imgComponent: 'magazine.svg',
        //       t: true,
        //       router: 'create-magazine'
        //     },
        //   ]
        // },
        // {
        //   id: 5,
        //   title: 'wi-wizard-attract',
        //   type: 'wizard',
        //   imgComponent: 'attract.svg',
        //   t: true,
        //   options: [
        //     {
        //       id: 51,
        //       title: 'wi-wizard-create-website',
        //       type: 'router',
        //       router: 'create-website',
        //       imgComponent: 'website.svg',
        //       t: true,
        //     },
        //     // {
        //     //   id: 52,
        //     //   title: 'wi-wizard-create-leads-funnel',
        //     //   type: 'router',
        //     //   imgComponent: 'leads.svg',
        //     //   t: true,
        //     //   router: 'leads-funnel'
        //     // },
        //     {
        //       id: 53,
        //       type: 'router',
        //       title: 'wi-wizard-create-webinar',
        //       imgComponent: 'webinar.svg',
        //       t: true,
        //       router: 'create-webinar'
        //     },
        //     {
        //       id: 54,
        //       type: 'router',
        //       title: 'wi-wizard-create-email-automations',
        //       imgComponent: 'automation.svg',
        //       router: 'automations',
        //       t: true,
        //     },
        //     {
        //       id: 55,
        //       title: 'wi-wizard-create-chat-bot',
        //       type: 'router',
        //       imgComponent: 'chat-bot.svg',
        //       t: true,
        //       router: 'create-chat-bot'
        //     },
        //     {
        //       id: 56,
        //       title: 'wi-wizard-create-magazine',
        //       type: 'router',
        //       imgComponent: 'magazine.svg',
        //       t: true,
        //       router: 'create-magazine'
        //     },
        //     // {
        //     //   id: 57, title: 'wi-wizard-create-quiz', t: true,imgComponent: 'quiz.svg',
        //     // },
        //   ]
        // },
      ],
    }
  },
  components: {
    TitleBar,
  },
  methods: {
    changeOptions(items) {

      let vm = this;

      this.returnData = JSON.parse(JSON.stringify(this.resultsSuggestion));
      this.resultsSuggestion = [];

      this.$nextTick(() => {
        vm.resultsSuggestion = JSON.parse(JSON.stringify(items));
        vm.canBack = true;
        vm.$forceUpdate();
      })

    },
    changeData() {

      this.resultsSuggestion = JSON.parse(JSON.stringify(this.returnData));
      this.canBack = false;
    },
    async redirects(item) {

      this.$Progress.start();

      if (item.type === 'wizard') {

        this.changeOptions(item.options);

        this.$Progress.finish();

      } else {
        if (item.type === 'router') {

          if(window.location.search.split('lang=')){
            this.$router.push({ path: item.router, query: { lang: window.location.search.split('lang=')[1] }})
          }else{
            this.$router.push(item.router);
          }

          this.$Progress.finish();

        } else {
          await axios.post(`https://intelligence.builderall.com/amount/` + item.id);
          setTimeout(() => {
            this.$Progress.finish();
            window.location.href = item.href;
          }, 300)
        }
      }
    },
    getImageData(item) {
      if (typeof item.img !== 'undefined' && item.img !== null) {
        return item.img;
      } else {
        if (typeof item.group !== 'undefined' && item.group.img !== null)
          return item.group.img;
        else {
          if (typeof item.imgComponent !== 'undefined' && item.imgComponent !== null) {
            return require('../../assets/start-steps/' + item.imgComponent);
          }
        }
      }
    },
  },
  async created() {
    try {

      this.email = this.$route.params.email;
      this.lang = this.$route.params.lang;

      this.$i18n.locale = this.lang;

      if(window.location.search.split('lang=')){
        this.$i18n.locale = window.location.search.split('lang=')[1];
      }

    } catch (e) {
      this.errors.push(e)
    }
  }
};
</script>
