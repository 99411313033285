<template>
  <div class="wizard-container container-fluid">
    <div class="container">

      <base-steps :img="$attrs.img" :title="$attrs.title" :sub-title="$attrs.subTitle"/>

      <div class="row">
        <div class="pt-5">
          <a :href="$attrs.url" target="_blank">
            <button class="btn btn-primary btn-next-step-ba">
              {{ $t('ct-start-here') }}
              <i class="fas fa-arrow-right"></i>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSteps from "@/components/baseSteps";

export default {
  name: 'PageStep',
  components: {
    BaseSteps
  }
};
</script>
