<template>
  <div :class="'list-container-col my-3 '+ classItem">
    <div class="list-item-container" @click="redirects(data)">
      <img class="list-item-container-img" :src="getImageData(data)" alt="data image"/>
      <div class="list-item-container-text-title">{{ data.t === true ? $t(data.title) : data.title }}</div>
      <div class="list-item-container-text-desc">
        {{
          ((data.description) ? data.description.substring(0, 150) + (data.description.length > 150 ? '...' : '') : '')
        }}
      </div>
    </div>
  </div>
</template>

<style>
.list-item-container {
  background: #FFFFFF 0 0 no-repeat padding-box;
  box-shadow: 0 2px 3px #00000026;
  border-radius: 10px;
  opacity: 1;
  padding: 15px 15px 20px;

}

.list-item-container:hover {
  cursor: pointer;
  box-shadow: 0 3px 8px #0000004a;
}

.list-item-container-text-title {
  text-align: left;
  font: normal normal bold 16px/16px Mulish;
  line-height: 20px;
  letter-spacing: 0;
  color: #333333;
  opacity: 1;
  text-transform: lowercase;
}

.list-item-container-text-title:first-letter {
  text-transform: uppercase;
}

.list-item-container-text-desc {
  text-align: left;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0;
  color: #6E6E6E;
  opacity: 1;
  min-height: 88px;
}

.list-item-container-img {
  width: 100%;
  min-height: 127px;
  max-height: 127px;
  margin-bottom: 20px;
  border-radius: 5px;
}

</style>

<script>
import axios from "axios";

export default {
  name: 'listItem',
  props: {
    data: Object,
    classItem: String,
    changeOptions: Function
  },
  methods: {
    getImageData(item) {
      console.log(item);
      if (typeof item.img !== 'undefined' && item.img !== null) {
        return item.img;
      } else {
        if (typeof item.group !== 'undefined' && item.group.img !== null)
          return item.group.img;
        else {
          if (typeof item.imgComponent !== 'undefined' && item.imgComponent !== null) {
            return require('../assets/start-steps/' + item.imgComponent);
          }
        }
      }
    },

    async redirects(item) {

      this.$Progress.start();

      if (item.type === 'wizard') {

        this.changeOptions(item.options);

        this.$Progress.finish();

      } else {
        if (item.type === 'router') {

          this.$router.push(item.router);

          this.$Progress.finish();

        } else {
          await axios.post(`https://intelligence.builderall.com/amount/` + item.id);
          setTimeout(() => {
            this.$Progress.finish();
            window.location.href = item.href;
          }, 300)
        }
      }
    }
  }
};
</script>
