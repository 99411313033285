<template>
  <div class="row">
    <div class="col-12 mx-auto container-search-title pt-5 position-relative">
      <slot></slot>
      <h1>
        {{ $t(this.title) }}
      </h1>
      <h2 v-html="$t(this.subTitle)"/>
    </div>
  </div>
</template>

<style lang="scss">
.container-search-title {

  text-align: left;

  h1 {
    text-align: center;
    font: normal normal 800 45px Mulish;
    color: #0080FC;
  }

  h2 {
    text-align: center;
    font: normal normal normal 18px Mulish;
    color: #434343;
  }
}
</style>

<script>

export default {
  name: 'titleBar',
  props: {
    title: String,
    subTitle: String,
  },
};
</script>
