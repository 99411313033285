import {createWebHistory, createRouter} from 'vue-router';
import Search from '@/views/Search.vue';
import Lateral from '@/views/Lateral.vue';
// import Quick from '@/views/Quick.vue';
import Initial from '@/views/Steps/Initial.vue';

import CreateWebsite from '@/views/Steps/CreateWebsite/index';
import CreateBlog from '@/views/Steps/CreateBlog/index';
import CreateWebinar from '@/views/Steps/CreateWebinar/index.vue';
import MembersArea from '@/views/Steps/MembersArea/index.vue';
import Leads from '@/views/Steps/LeadsFunnel/index.vue';
import CreateMagazine from '@/views/Steps/CreateMagazine/index.vue';
import PhysicalStore from '@/views/Steps/PhysicalStore/index.vue';
import DigitalStore from '@/views/Steps/PhysicalStore/index.vue';

import PageSteps from '@/views/Steps/PageStep';
import NotFoundComponent from '@/views/NotFoundComponent.vue';

const routes = [
  {
    path: '/:lang/:email',
    name: 'Search',
    component: Search,
  },
  {
    path: '/lateral/:lang/:email',
    name: 'Lateral',
    component: Lateral,
  },
  // {
  //   path: '/quick/:lang/:email',
  //   name: 'Quick',
  //   component: Quick,
  // },
  {
    path: '/start',
    name: 'Initial',
    component: Initial,
  },
  {
    path: '/create-website',
    name: 'CreateWebsite',
    component: CreateWebsite,
  },
  {
    path: '/create-blog',
    name: 'CreateBlog',
    component: CreateBlog,
  },
  {
    path: '/create-webinar',
    name: 'CreateWebinar',
    component: CreateWebinar,
  },
  {
    path: '/members',
    name: 'MembersArea',
    component: MembersArea,
  },
  {
    path: '/leads-funnel',
    name: 'Leads',
    component: Leads,
  },
  {
    path: '/create-magazine',
    name: 'CreateMagazine',
    component: CreateMagazine,
  },
  {
    path: '/work-with-builderall',
    name: 'WorkWithBuilderall',
    component: PageSteps,
    props: {
      img: 'work-with-builderall.svg',
      title: 'wi-title-work-with-builderall',
      subTitle: 'wi-desc-work-with-builderall',
      url: 'https://office.builderall.com/us/office/onboarding-funnel',
    }
  },
  {
    path: '/automations',
    name: 'Automations',
    component: PageSteps,
    props: {
      img: 'automations.svg',
      title: 'wi-title-automations',
      subTitle: 'wi-desc-automations',
      url: 'https://office.builderall.com/us/office/cheetah-funnel-builder',
    }
  },
  {
    path: '/booking',
    name: 'Booking',
    component: PageSteps,
    props: {
      img: 'booking.svg',
      title: 'wi-title-booking',
      subTitle: 'wi-desc-booking',
      url: 'https://office.builderall.com/us/office/booking',
    }
  },
  {
    path: '/create-chat-bot',
    name: 'CreateChatBot',
    component: PageSteps,
    props:{
      img: 'create-chat-bot.svg',
      title: 'wi-title-create-chat-bot',
      subTitle: 'wi-desc-create-chat-bot',
      url: 'https://office.builderall.com/us/office/new-chat-builder',
    }
  },
  {
    path: '/physical-store',
    name: 'PhysicalStore',
    component: PhysicalStore,
    props: {
      digital: false
    }
  },
  {
    path: '/digital-store',
    name: 'DigitalStore',
    component: DigitalStore,
    props: {
      digital: true
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundComponent',
    component: NotFoundComponent,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
